
import * as dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import localizedFormat from 'dayjs/plugin/localizedFormat'
import { isUndefined, find, indexOf, filter, size, uniq, hasIn, startsWith } from 'lodash';

/*
a successful login will return a json object,
{
  user: {role:{}},
  common: {roles[]}
}

See the details in README
*/

var helpers = {
    canUser: function (a, adminOverride) {
        var role = this.userRole  // set in ProtectedLayout.jsx
        var roles = this.roles
    
        if (adminOverride === true && role.isAdmin) return true
    
        if (isUndefined(role)) return false
        if (isUndefined(roles)) return false
        if (hasIn(role, '_id')) role = role._id
        var rolePerm = find(roles, { _id: role })
        if (isUndefined(rolePerm)) return false
        if (indexOf(rolePerm.grants, '*') !== -1) return true
        if (isUndefined(a)) return false
    
        var actionType = a.split(':')[0]
        var action = a.split(':')[1]
    
        if (isUndefined(actionType) || isUndefined(action)) return false
    
        var result = filter(rolePerm.grants, function (value) {
          if (startsWith(value, actionType + ':')) return value
        })
    
        if (isUndefined(result) || size(result) < 1) return false
        if (size(result) === 1) {
          if (result[0] === '*') return true
        }
    
        var typePerm = result[0].split(':')[1].split(' ')
        typePerm = uniq(typePerm)
    
        if (indexOf(typePerm, '*') !== -1) return true
    
        return indexOf(typePerm, action) !== -1
      }
};

helpers.formatDate = function (date, format) {
  /*
  var timezone = this.getTimezone()
  if (!timezone) {
    timezone = 'Etc/UTC'
  }
  */
  dayjs.extend(utc);
  dayjs.extend(localizedFormat)
  return dayjs.utc(date)  //not dayjs(), which is local current time
    .local()
    .format(format)
}

helpers.hasPermOverRole = function (ownerRole, extRole, action, adminOverride) {
  if (action && !helpers.canUser(action, adminOverride)) return false
  if (!extRole) extRole = this.userRole

  if (!_.isObject(ownerRole) || !_.isObject(extRole)) {
    console.log('Invalid Role Sent to helpers.hasPermOverRole. [Must be role obj]')
    console.log('Owner: ' + ownerRole)
    console.log('ExtRole: ' + extRole)
    return false
  }

  if (extRole.role) {
    console.warn(
      'Seems like a user object was sent to helpers.hasPermOverRole --- [extRole must be a role object or null]'
    )
    return false
  }

  if (ownerRole._id === extRole._id) return true

  if (adminOverride === true) {
    if (extRole && extRole.isAdmin) {
      return true
    } else {
      const r = this.roles
      const role = _.find(r, function (_role) {
        return _role._id.toString() === extRole._id.toString()
      })
      if (!_.isUndefined(role) && role.isAdmin) return true
    }
  }

  if (!helpers.hasHierarchyEnabled(extRole._id)) {
    return ownerRole._id === extRole._id
  }

  const roles = helpers.parseRoleHierarchy(extRole._id)
  // console.log('My Role ID: ', extRole._id)
  // console.log('Hierarchy: ', roles)
  const i = _.find(roles, function (o) {
    return o.toString() === ownerRole._id.toString()
  })

  // console.log('Found in Hierarchy: ', i)

  return !_.isUndefined(i)
}

helpers.hasHierarchyEnabled = function (roleId) {
  const roles = this.roles
  const role = _.find(roles, function (o) {
    return o._id.toString() === roleId.toString()
  })
  if (_.isUndefined(role) || _.isUndefined(role.hierarchy)) throw new Error('Invalid Role: ' + roleId)
  return role.hierarchy
}

helpers.parseRoleHierarchy = function (roleId) {
  var roleOrder = this.roleOrder
  if (_.isUndefined(roleOrder)) return []
  roleOrder = roleOrder.order

  var idx = _.findIndex(roleOrder, function (i) {
    return i.toString() === roleId.toString()
  })
  if (idx === -1) return []

  return _.rest(roleOrder, idx)
}

export default helpers;

