import React, { lazy, Suspense, useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react'

import api from '../api'

import { Col,  Form, Input, Modal, Row, Select} from 'antd'

import TrinityEditor from './TrinityEditor'
//const TrinityEditor = lazy(() => import('./TrinityEditor'));


import svgTicket from '../assets/images/ticket-svgrepo-com.svg';

// for click outside, https://blog.logrocket.com/detect-click-outside-react-component-how-to/
const CreateTicketModal = observer( (props) => {

  const { onCreate, visible, onCancel } = props;

  const [accounts, setAccounts] = useState([]);

  useEffect(() => {
    fetchAccountsCreateTicket({ type: 'all', limit: 1000 })

    return () => {

    };
  }, []);


  const fetchAccountsCreateTicket =  async (payload) => {

    const res = await api.accounts.getWithPage(payload);
    if(res.success) {
      setAccounts(res.accounts)

    }

  };

  const allowAgentUserTickets = true;

/*
  const allowAgentUserTickets =
  props.rootStore.common.ticketSettings.allowAgentUserTickets &&
  (props.rootStore.shared.sessionUser.role.isAdmin || props.rootStore.shared.sessionUser.role.isAgent)
*/
 
  const mappedAccounts = accounts
  .map(a => {
    return { label: a.fullname, value: a._id}
  })
  

  const mappedGroups = props.rootStore.common.groups
    .map(grp => {
      return { label: grp.name, value: grp._id }
    })

  const mappedTicketTypes = props.rootStore.common.ticketTypes.map(type => {
    return { label: type.name, value: type._id }
  })
  const mappedTicketTags = props.rootStore.common.ticketTags.map(tag => {
    return { label: tag.name, value: tag._id }
  })  

//  const priorities = orderBy(props.rootStore.common.defaultTicketType.priorities, ['migrationNum'])
// need to get the config first
  const priorities = props.rootStore.common.priorities.map(priority => {
    return { label: priority.name, value: priority._id }});

//  console.log(priorities);
  const [form] = Form.useForm();
  return (
    <Modal open={visible} maskClosable={false}
      title={<div><img src={svgTicket} width={24} height={24}/> Create a ticket</div>}
      width={800}
      okText="Submit"
      cancelText="Close"
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields();
//            values.issue = description; //add md value
            onCreate(values);
          })
          .catch((info) => {
            console.log('Validate Failed:', info);
          });
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={{
          modifier: 'public',
          type: props.rootStore.common.defaultTicketType._id,
          owner: props.rootStore.common.loggedInAccount._id,
        }}
      >
        <Form.Item name="subject" label="Subject"
          rules={[{ min: 3, message: 'Subject name must be minimum 3 characters.' },
          {
            required: true,
            message: 'Subject must contain at least 3 characters',
          },
          ]}
        >
            <Input placeholder="Type concise words for your issue."/>
        </Form.Item>

        <Row gutter={16}>
          <Col span={12} >
          <Form.Item name="group" label="Group">
          <Select options={mappedGroups} placeholder="Choose a group"/>
        </Form.Item>
          </Col>
          <Col span={12} >
          {allowAgentUserTickets && (
          <Form.Item name="owner" label="Owner" >
              <Select options={mappedAccounts} placeholder="Choose an account"/>
          </Form.Item>

          )}
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12} >
            <Form.Item name="type" label="Type">
              <Select  options={mappedTicketTypes} placeholder="Choose a type"/>
            </Form.Item>
          </Col>
          <Col span={12} >
            <Form.Item name="priority" label="Priority">
                <Select  options={priorities} placeholder="Choose a priority"/>
            </Form.Item>
          </Col>
        </Row>
        <Form.Item name="tags" label="Tags">
            <Select mode="multiple" options={mappedTicketTags} placeholder="Select multiple tags"/>
        </Form.Item>
        <Form.Item name="issue" label="Detail">
          <Input.TextArea rows={4} />       
        </Form.Item>        
         
      </Form>
    </Modal>
  );
})

export default CreateTicketModal;