import React from 'react';
import {createRoot} from 'react-dom/client';
/*
import { applyMiddleware, createStore, compose } from 'redux'
import { Provider } from 'react-redux'
import createSagaMiddleware from 'redux-saga'
import { middleware as thunkMiddleware } from 'redux-saga-thunk'
import IndexReducer from './reducers'
import IndexSagas from './sagas'
*/

import App from './App';

/*
// https://stackoverflow.com/questions/51794553/how-do-i-create-configuration-for-axios-for-default-request-headers-in-every-htt
// Add a request interceptor
axios.interceptors.request.use(function (config) {
  // Do something before request is sent
  if (!config.headers.accesstoken) {
    let accessToken = localStorage.getItem("accessToken");
    if (accessToken) {
      config.headers.accesstoken = accessToken;
    }
  }

  return config;
}, function (error) {
  // Do something with request error
  return Promise.reject(error);
});


const preloadedState = {
//  common: window.trudesk.__PRELOADED_STATE__,
  common: {}
}

const sagaMiddleware = createSagaMiddleware()
const store = createStore(IndexReducer, preloadedState, compose(applyMiddleware(thunkMiddleware, sagaMiddleware)))
window.store = store;
sagaMiddleware.run(IndexSagas);
*/

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(

  <>
  {/*<React.StrictMode>}
         <Provider store={store}>*/}
      <App />
    {/* </Provider>
  </React.StrictMode>,*/}
  </>
);


