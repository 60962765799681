import { computed } from "mobx"
import {
  connectReduxDevTools,
  idProp,
  model,
  Model,
  modelAction,
  _await, 
  modelFlow,
  ModelAutoTypeCheckingMode,
  registerRootStore,
  setGlobalConfig,
  tProp,
  prop,
  types,
} from "mobx-keystone"

import api from '../api'

// for this example we will enable runtime data checking even in production mode
setGlobalConfig({
  modelAutoTypeChecking: ModelAutoTypeCheckingMode.AlwaysOn,
})

// the model decorator marks this class as a model, an object with actions, etc.
// the string identifies this model type and must be unique across your whole application
@model("stores/Ticket")
export class Ticket extends Model({
  // here we define the type of the model data, which is observable and snapshotable
  // and also part of the required initialization data of the model

  // in this case we use runtime type checking,
  id: idProp, // an optional string that will use a random id when not provided
  text: tProp(types.string), // a required string
  done: tProp(types.boolean, false), // an optional boolean that will default to false

  // if we didn't require runtime type checking we could do this
  // id: idProp,
  // text: prop<string>(),
  // done: prop(false)
}) {
  // the modelAction decorator marks the function as a model action, giving it access
  // to modify any model data and other superpowers such as action
  // middlewares, replication, etc.
  @modelAction
  setDone(done) {
    this.done = done
  }

  @modelAction
  setText(text) {
    this.text = text
  }
}

@model("stores/Tickets")
export class Tickets extends Model({
  // in this case the default uses an arrow function to create the object since it is not a primitive
  // and we need a different array for each model instance
//  todos: tProp(types.array(types.model(Ticket)), () => []),
  // how can we use prop for object type, non-primitive data type, like string, boolean.
  configuration: tProp(types.object(() => ({}))),
  shared: tProp(types.object(() => ({}))),
  common: tProp(types.object(() => ({}))),
  resTickets: tProp(types.object(() => ({}))),
  loggedIn: tProp(types.boolean, false), // an optional boolean that will default to false

}) {
  // standard mobx decorators (such as computed) can be used as usual, since props are observables
  @computed
  get tickets() {
    if(this.resTickets.tickets !== undefined) return this.resTickets.tickets
    else return [];
  }
  @computed
  get sessionUser() {
    // why we cannot use shared as the prop and  access shared.sessionUser in react component?
    if(this.shared.sessionUser !== undefined) return this.shared.sessionUser
    else return {};
  }
  @computed
  get settings() {
    if(this.configuration.settings !== undefined) return this.configuration.settings
    else return {};
  }

  @computed
  get config() {
     return this.configuration
  }

  @modelAction
  setLoggedIn(loggedIn) {
    this.loggedIn = loggedIn
  }

  @modelAction
  fetchTickets(query) {
    console.log(query)
  }

  @modelAction
  setCommon(common) {
    this.common = common; // json object to proxy?
  }

  @modelAction
  addSessionUserAndRoles(sessionUser, roles) {
    this.shared.sessionUser  = sessionUser;
    this.common.roles = roles;
  }

    // JavaScript version

  @modelFlow
  // we use `function*` (a function generator) where we would use `async`
  *fetchTicketsAsync(payload) {
    // we use `yield* _await(X)` where we would use `await X`
    // note: it is `yield*`, NOT just `yield`; `_await` is a function that has to be imported

//      const myBooks = yield* _await(myBackendClient.getBooks(payload))
//      this.books = myBooks

    let response = null
    if (payload.type === 'search') response = yield* _await(api.tickets.search(payload))
    else response = yield* _await(api.tickets.getWithPage(payload))

    //apart from tickets, there are other properties in the response,
    /*
  "success": true,
  "count": 1,
  "totalCount": 1,
  "page": 0,
  "prevPage": 0,
  "nextPage": 0
    */
    this.resTickets = response;

  }

  @modelFlow
  // we use `function*` (a function generator) where we would use `async`
  *fetchConfigurationAsync() {
    // we use `yield* _await(X)` where we would use `await X`
    // note: it is `yield*`, NOT just `yield`; `_await` is a function that has to be imported

//      const myBooks = yield* _await(myBackendClient.getBooks(payload))
//      this.books = myBooks

    let response = yield* _await( api.settings.fetch())

    this.configuration = response.settings.data;

  }

}

export function createRootStore() {
  //not sure how to use onAttachedToRootStore? so we use the old-fashion way to recreate the store
  let json = localStorage.getItem("rootStore");
  if(json) {
    json = JSON.parse(json)
  }
  else {
    json = {
      configuration: {},
      shared: {},
      common: {},
      resTickets: {},
    }
  }

  // the parameter is the initial data for the model
  const rootStore = new Tickets(json)

  // although not strictly required, it is always a good idea to register your root stores
  // as such, since this allows the model hook `onAttachedToRootStore` to work and other goodies
  registerRootStore(rootStore)

  return rootStore
}